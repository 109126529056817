import React, { useState } from "react";
import styles from "./Menu.module.scss";
import NavItem from "assets/NavItem";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import usePayInStore from "store/payInStore";
import usePayInOutStore from "store/payInOutStore";
import usePayOutStore from "store/payOutStore";

export const Menu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  const { payInData } = usePayInStore()
  const { payInOutData } = usePayInOutStore()
  const { payOutData } = usePayOutStore()

  const handleClick = (page: string) => {
    setCurrentPath(page);
    navigate(page);
  };

  // let activeBar = {
  //   backgroundColor: "#EBF442",
  //   width: "33.3%",
  //   height: "100%",
  //   marginLeft:
  //     (currentPath === "/payinout" || currentPath === `/detailsinout`)
  //       ? "0%"
  //       : currentPath === "/payin" || currentPath === `/detailsin`
  //       ? "33.3%"
  //       : "66.6%",
  // };


  return (
    <div className={styles.menuWrapper}>
      <div className={styles.items}>
        {payInOutData && payInOutData.length > 0 &&
          <div
            className={`${styles.item} ${
              currentPath === '/payinout' ? styles.active : ""
            }`}
            onClick={() => handleClick('/payinout')}
          >
            <div className={styles.icon}>
              <NavItem
                className={styles.navIcon}
                stroke={currentPath === '/payinout' ? "black" : "#A8A8A8"}
              />
            </div>
            <div className={styles.name}>Приём + Выплаты</div>
          </div>
        }
        {payInData && payInData.length > 0 &&
          <div
            className={`${styles.item} ${
              currentPath === '/payin' ? styles.active : ""
            }`}
            onClick={() => handleClick('/payin')}
          >
            <div className={styles.icon}>
              <NavItem
                className={styles.navIcon}
                stroke={currentPath === '/payin' ? "black" : "#A8A8A8"}
              />
            </div>
            <div className={styles.name}>Приём</div>
          </div>
        }
        {payOutData && payOutData.length > 0 &&
          <div
            className={`${styles.item} ${
              currentPath === '/payout' ? styles.active : ""
            }`}
            onClick={() => handleClick('/payout')}
          >
            <div className={styles.icon}>
              <NavItem
                className={styles.navIcon}
                stroke={currentPath === '/payout' ? "black" : "#A8A8A8"}
              />
            </div>
            <div className={styles.name}>Выплаты</div>
          </div>
        }
      </div>
      {/* <div className={styles.activeNavLine}>
        <div style={activeBar}></div>
      </div> */}
    </div>
  );
};
