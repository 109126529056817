import { useEffect, useState } from "react";

const ProgressLoading = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + (100 / 1500) * 30;
        return newProgress > 100 ? 100 : newProgress;
      });
    }, 30);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#A2A0A9" }}>
        <div
          style={{
            width: `${progress}%`,
            height: "5px",
            backgroundColor: "#EFF02A",
            transition: "width 0.3s ease",
          }}
        ></div>
      </div>
    </>
  );
};

export default ProgressLoading;
