import React from "react";

const Header = ({className}: {className?: string}) => {
  return (
    <svg
      width="343"
      height="102"
      viewBox="0 0 343 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1_7052)">
        <path
          d="M4.05691 16.3803C0.787109 22.7976 0.787109 31.1984 0.787109 48V64.7959C0.787109 81.5975 0.787109 89.9983 4.05691 96.4156C6.93312 102.06 11.5225 106.65 17.1674 109.526C23.5847 112.796 31.9855 112.796 48.7871 112.796H324.741C334.943 112.796 343.213 104.526 343.213 94.324C343.213 84.1222 334.943 75.8521 324.741 75.8521H308.832C292.03 75.8521 283.629 75.8521 277.212 72.5822C271.567 69.706 266.978 65.1166 264.101 59.4718C260.832 53.0544 260.832 41.6536 260.832 24.8521C260.832 11.1266 249.705 0 235.979 0H48.7871C31.9855 0 23.5847 0 17.1674 3.2698C11.5225 6.14601 6.93312 10.7354 4.05691 16.3803Z"
          fill="white"
        />
        <path
          d="M0.787109 64.1731L343.213 94.1731V102H0.787109V64.1731Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_7052">
          <rect
            width="342"
            height="102"
            fill="white"
            transform="translate(0.787109)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Header;
