import axios from "axios"
import { IInputData } from "types/IData"
export const OFFER_TOKEN = "offer_token"
export const BASE_URL = 'https://offer.stbl.world/-api-'

export const API = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
})

API.interceptors.request.use(
    (config) => {
      if (typeof localStorage !== "undefined") {
        config.headers["Access"] = `${localStorage.getItem(OFFER_TOKEN)}`
        config.headers["Authorization"] = `${
          localStorage && localStorage?.getItem(OFFER_TOKEN)
        }`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
)

export const fetchData = async (): Promise<IInputData | undefined> => {
    try {
        const { data } = await API.get('/get_data')
        return data
    } catch (e: any) {
        console.error(e)
    }
}