import React, { useEffect } from "react";
import styles from "./PayInOutDetails.module.scss";
import { Menu } from "components/Menu/Menu";
import Header from "assets/Header";
import prev from "assets/prev.svg";
import next from "assets/next.svg";
import { useLocation, useNavigate } from "react-router-dom";
import usePayInOutStore from "store/payInOutStore";

const PayInOutDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentItemIndex, setCurrentItemIndex] = React.useState(0);
  const [touchStartX, setTouchStartX] = React.useState<number | null>(null);
  const [touchEndX, setTouchEndX] = React.useState<number | null>(null);
  const { payInOutSlider } = usePayInOutStore();

  useEffect(() => {
    const { method, name } = location.state || {};
    if (method && name && payInOutSlider?.data) {
      const index = payInOutSlider.data.findIndex(
        (item) =>
          item.method.toLowerCase() === method.toLowerCase() &&
          item.name.toLowerCase() === name.toLowerCase()
      );
      if (index !== -1) {
        setCurrentItemIndex(index);
      }
    }
  }, [location.state, navigate, payInOutSlider]);

  const prevItem = () => {
    if (payInOutSlider?.data) {
      setCurrentItemIndex((prevIndex) =>
        prevIndex === 0 ? payInOutSlider.data.length - 1 : prevIndex - 1
      );
    }
  };

  const nextItem = () => {
    if (payInOutSlider?.data) {
      setCurrentItemIndex((prevIndex) =>
        prevIndex === payInOutSlider.data.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  if (!payInOutSlider) {
    return <></>;
  }

  const currentItem = payInOutSlider.data[currentItemIndex];
  const progressPercentage =
    ((currentItemIndex + 1) / payInOutSlider.total) * 100;

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX !== null && touchEndX !== null) {
      if (touchStartX - touchEndX > 50) {
        nextItem();
      } else if (touchEndX - touchStartX > 50) {
        prevItem();
      }
    }
    setTouchStartX(null);
    setTouchEndX(null);
  };

  return (
    <div
      className={styles.wrapper}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className={styles.navigation}>
        <div className={styles.steps}>
          {currentItemIndex + 1} / {payInOutSlider.total}
        </div>
        <div className={styles.progressBar}>
          <div
            className={styles.progressBarInner}
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
        <div className={styles.buttons}>
          <img src={prev} alt="prev" onClick={prevItem} />
          <img src={next} alt="next" onClick={nextItem} />
        </div>
      </div>

      <div className={styles.details}>
        <div className={styles.header}>
          <Header />
          <div className={styles.content}>
            <div className={styles.title}>{currentItem.name}</div>

            <div className={styles.desc}>
              <p dangerouslySetInnerHTML={{ __html: currentItem.code }} />
              <span
                dangerouslySetInnerHTML={{ __html: currentItem.currency }}
              />
            </div>
          </div>
          <img
            src={`/flags/${currentItem.code.toUpperCase()}.svg`}
            alt={currentItem.code}
            className={styles.flag}
            width={44}
            height={44}
          />
        </div>

        <div className={styles.body}>
          <div className={styles.info}>
            <div className={styles.line}>
              <p>Метод</p>
              <span dangerouslySetInnerHTML={{ __html: currentItem.method }} />
            </div>
            <div className={styles.line}>
              <p>Карты</p>
              <span dangerouslySetInnerHTML={{ __html: currentItem.cards }} />
            </div>

            <div className={styles.line}>
              <p>Тип трафика и клиентов</p>
              <span dangerouslySetInnerHTML={{ __html: currentItem.type }} />
            </div>
          </div>

          <div className={styles.comissions}>
            <h3>Комиссии</h3>

            {currentItem.commissions.virtual && (
              <div className={styles.line}>
                <p>Прием через виртуальный банк</p>
                <span
                  dangerouslySetInnerHTML={{
                    __html: currentItem.commissions.virtual,
                  }}
                />
              </div>
            )}

            {currentItem.commissions.QRcode && (
              <div className={styles.line}>
                <p>Прием через QR code и электронные кошельки</p>
                <span
                  dangerouslySetInnerHTML={{
                    __html: currentItem.commissions.QRcode,
                  }}
                />
              </div>
            )}

            {currentItem.commissions.in && (
              <div className={styles.line}>
                <p>Прием</p>
                <span
                  style={{ flexDirection: "column" }}
                  dangerouslySetInnerHTML={{
                    __html: currentItem.commissions.in,
                  }}
                />
              </div>
            )}

            {currentItem.commissions.out && (
              <div className={styles.line}>
                <p>Выплаты</p>
                <span
                  style={{ flexDirection: "column" }}
                  dangerouslySetInnerHTML={{
                    __html: currentItem.commissions.out,
                  }}
                />
              </div>
            )}

            {currentItem.commissions.convert && (
              <div className={styles.line}>
                <p>Конвертация в USDT</p>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: currentItem.commissions.convert,
                    }}
                  />
                  <span
                    style={{ color: "#7A7A7B" }}
                    dangerouslySetInnerHTML={{
                      __html: currentItem.commissions.dopInfo,
                    }}
                  />
                </span>
              </div>
            )}

            {currentItem.commissions.chBack && (
              <div className={styles.line}>
                <p>Charge Back</p>
                <span
                  dangerouslySetInnerHTML={{
                    __html: currentItem.commissions.chBack,
                  }}
                />
              </div>
            )}
          </div>

          {currentItem.limits && (
            <div className={styles.limits}>
              <h3>Лимиты</h3>

              {currentItem.limits.UPI && (
                <div className={styles.line}>
                  <p>Прием UPI</p>
                  <span
                    dangerouslySetInnerHTML={{ __html: currentItem.limits.UPI }}
                  />
                </div>
              )}

              {currentItem.limits.IMPS && (
                <div className={styles.line}>
                  <p>Прием IMPS</p>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: currentItem.limits.IMPS,
                    }}
                  />
                </div>
              )}

              {currentItem.limits.in && (
                <div className={styles.line}>
                  <p>Прием</p>
                  <span
                    dangerouslySetInnerHTML={{ __html: currentItem.limits.in }}
                  />
                </div>
              )}

              {currentItem.limits.out && (
                <div className={styles.line}>
                  <p>Выплаты</p>
                  <span
                    dangerouslySetInnerHTML={{ __html: currentItem.limits.out }}
                  />
                </div>
              )}

              {currentItem.limits.tranz && (
                <div className={styles.line}>
                  <p>Кол-во транз. с одной карты, сутки/мес:</p>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: currentItem.limits.tranz,
                    }}
                  />
                </div>
              )}
            </div>
          )}

          <div className={styles.settlement}>
            <h3>Settlement</h3>

            {currentItem.settlement.period && (
              <div className={styles.line}>
                <p>Период</p>
                <span
                  dangerouslySetInnerHTML={{
                    __html: currentItem.settlement.period,
                  }}
                />
              </div>
            )}

            {currentItem.settlement.netting && (
              <div className={styles.line}>
                <p>Неттинг</p>
                <span
                  dangerouslySetInnerHTML={{
                    __html: currentItem.settlement.netting,
                  }}
                />
              </div>
            )}
          </div>

          {currentItem.fin ? (
            <div className={styles.fin}>
              <h3>Фин. условия</h3>

              {currentItem.fin.rolling && (
                <div className={styles.line}>
                  <p>Rolling Reserve</p>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: currentItem.fin.rolling,
                    }}
                  />
                </div>
              )}

              {currentItem.fin.charge && (
                <div className={styles.line}>
                  <p>Charge Back fee</p>
                  <span
                    dangerouslySetInnerHTML={{ __html: currentItem.fin.charge }}
                  />
                </div>
              )}

              {currentItem.fin.refund && (
                <div className={styles.line}>
                  <p>Refund fee</p>
                  <span
                    dangerouslySetInnerHTML={{ __html: currentItem.fin.refund }}
                  />
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div>
        <Menu />
      </div>
    </div>
  );
};

export default PayInOutDetails;
