import React from "react";

const NavItem = ({stroke, className}: {stroke?: string, className: string}) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.09613 10.2229H25.9044"
        stroke={stroke}
        strokeWidth="1.57177"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9717 15.0692H8.94275"
        stroke={stroke}
        strokeWidth="1.57177"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.9044 13.8576V9.01127C25.9044 7.00006 24.2809 5.37656 22.2696 5.37656H7.7308C5.71959 5.37656 4.09608 7.00006 4.09608 9.01127V18.7038C4.09608 20.715 5.71959 22.3385 7.7308 22.3385H11.3655"
        stroke={stroke}
        strokeWidth="1.57177"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1157 19.7202H17.3255L20.6051 16.4405"
        stroke={stroke}
        strokeWidth="1.57177"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.8606 26.7271L27.1157 23.4474H17.3255"
        stroke={stroke}
        strokeWidth="1.57177"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NavItem;
