import { IInputData } from "types/IData";

export const transformData = (data: IInputData) => {
    const PayInData = data.payindata.map(item => ({
        logo: item.logo,
        code: item.code,
        name: item.name,
        method: [item.method]
    }));

    const PayInSlider = {
        total: data.payinslider.length,
        data: data.payinslider.map(item => ({
            id: item.id,
            logo: item.logo,
            code: item.code,
            name: item.name,
            currency: item.currency,
            method: item.method,
            cards: item.cards,
            type: item.type,
            commissions: {
                in: item.comissions_in,
                out: item.comissions_out,
                convert: item.comissions_convert,
                dopInfo: item.comissions_dopinfo
            },
            limits: {
                maxCard: item.limits_maxcard,
                in: item.limits_in,
                out: item.limits_out
            },
            settlement: {
                period: item.settlement_period
            },
            fin: item.fin_rolling ? { rolling: item.fin_rolling } : undefined
        }))
    };

    const PayInOutData = data.payinoutdata.map(item => ({
        code: item.code,
        name: item.name,
        method: [item.method],
        cards: item.cards,
        type: item.type,
        commissions: {
            in: item.comissions_in,
            out: item.comissions_out,
            convert: item.comissions_convert,
            dopInfo: item.comissions_dopinfo
        },
        limits: {
            in: item.limits_int,
            out: item.limits_out
        },
        settlement: {
            period: item.settlement_period
        }
    }));

    const PayInOutSlider = {
        total: data.payinoutslider.length,
        data: data.payinoutslider.map(item => ({
            id: item.id,
            logo: item.logo,
            code: item.code,
            name: item.name,
            currency: item.currency,
            method: item.method,
            cards: item.cards,
            type: item.type,
            commissions: {
                virtual: item.comissions_virtual,
                QRcode: item.comissions_qrcode,
                in: item.comissions_in,
                out: item.comissions_out,
                convert: item.comissions_convert,
                dopInfo: item.comissions_dopinfo,
                chBack: item.comissions_chback
            },
            limits: {
                onCard: item.limits_oncard,
                UPI: item.limits_upi,
                IMPS: item.limits_imps,
                in: item.limits_in,
                out: item.limits_out,
                tranz: item.limits_tranz
            },
            settlement: {
                period: item.settlement_period,
                netting: item.settlement_netting
            },
            fin: item.fin_rolling || item.fin_charge || item.fin_refund ? { 
                rolling: item.fin_rolling, 
                charge: item.fin_charge, 
                refund: item.fin_refund 
            } : undefined
        }))
    };

    const PayOutData = data.payoutdata.map(item => ({
        icon: item.icon,
        code: item.code,
        name: item.name,
        method: [item.method]
    }));

    const PayOutSlider = {
        total: data.payoutslider.length,
        data: data.payoutslider.map(item => ({
            id: item.id,
            logo: item.logo,
            code: item.code,
            name: item.name,
            currency: item.currency,
            method: item.method,
            cards: item.cards,
            commissions: {
                virtual: item.comissions_virtual,
                QRcode: item.comissions_qrcode,
                in: item.comissions_in,
                out: item.comissions_out,
                convert: item.comissions_convert,
                dopInfo: item.comissions_dopinfo,
                chBack: item.comissions_chback,
                tranz: item.comissions_tranz
            },
            limits: {
                dayMax: item.limits_daymax,
                cross: item.limits_cross,
                UK: item.limits_uk,
                moMax: item.limits_momax,
                cross2: item.limits_cross2,
                UK2: item.limits_uk2,
                minMax: item.limits_mimax,
                limit: item.limit_limit,
                IMPS: item.limit_imps,
                in: item.limit_in,
                out: item.limit_out,
                tranz: item.limit_tranz
            },
            fin: item.fin_rolling || item.fin_course || item.fin_method ? { 
                rolling: item.fin_rolling, 
                course: item.fin_course, 
                method: item.fin_method 
            } : undefined
        }))
    };

    return {
        PayInData,
        PayInSlider,
        PayInOutData,
        PayInOutSlider,
        PayOutData,
        PayOutSlider
    };
}