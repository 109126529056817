import React from "react";

const Lupa = ({className}: {className: string}) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.79248 11.5259C7.47868 11.5259 8.12337 11.3958 8.72656 11.1357C9.32975 10.8701 9.861 10.5076 10.3203 10.0483C10.7796 9.58903 11.1393 9.05778 11.3994 8.45459C11.665 7.8514 11.7979 7.20671 11.7979 6.52051C11.7979 5.83431 11.665 5.18962 11.3994 4.58643C11.1393 3.98324 10.7796 3.45199 10.3203 2.99268C9.861 2.52783 9.32975 2.16536 8.72656 1.90527C8.12337 1.64518 7.47868 1.51514 6.79248 1.51514C6.10628 1.51514 5.46159 1.64518 4.8584 1.90527C4.25521 2.16536 3.72119 2.52783 3.25635 2.99268C2.79704 3.45199 2.43734 3.98324 2.17725 4.58643C1.91715 5.18962 1.78711 5.83431 1.78711 6.52051C1.78711 7.20671 1.91715 7.8514 2.17725 8.45459C2.43734 9.05778 2.79704 9.58903 3.25635 10.0483C3.72119 10.5076 4.25521 10.8701 4.8584 11.1357C5.46159 11.3958 6.10628 11.5259 6.79248 11.5259ZM6.79248 12.9038C5.9126 12.9038 5.08805 12.7378 4.31885 12.4058C3.54964 12.0737 2.87174 11.6144 2.28516 11.0278C1.69857 10.4412 1.23926 9.76335 0.907227 8.99414C0.575195 8.22493 0.40918 7.40039 0.40918 6.52051C0.40918 5.64062 0.575195 4.81608 0.907227 4.04688C1.23926 3.27214 1.69857 2.59424 2.28516 2.01318C2.87174 1.4266 3.54964 0.967285 4.31885 0.635254C5.09359 0.303223 5.91813 0.137207 6.79248 0.137207C7.67236 0.137207 8.49691 0.303223 9.26611 0.635254C10.0353 0.967285 10.7132 1.4266 11.2998 2.01318C11.8864 2.59977 12.3457 3.27767 12.6777 4.04688C13.0098 4.81608 13.1758 5.64062 13.1758 6.52051C13.1758 7.40039 13.0098 8.22493 12.6777 8.99414C12.3457 9.76335 11.8864 10.4412 11.2998 11.0278C10.7132 11.6144 10.0353 12.0737 9.26611 12.4058C8.49691 12.7378 7.67236 12.9038 6.79248 12.9038ZM15.1182 15.917C14.9909 15.917 14.8691 15.8949 14.7529 15.8506C14.6367 15.8063 14.5316 15.7371 14.4375 15.6431L10.0298 11.2271L11.3911 9.91553L15.7739 14.3066C15.868 14.3952 15.9344 14.4976 15.9731 14.6138C16.0174 14.73 16.0396 14.849 16.0396 14.9707C16.0396 15.1478 15.998 15.3055 15.915 15.4438C15.8376 15.5877 15.7297 15.7012 15.5913 15.7842C15.453 15.8727 15.2952 15.917 15.1182 15.917Z"
        fill="#3C3C43"
        fillOpacity="0.6"
      />
    </svg>
  );
};

export default Lupa;
