import { IPayInData } from "types/IPayInData"
import { IPayInSlider } from "types/IPayInSlider"
import create, { GetState, SetState } from "zustand"

interface PayInStore {
  payInData: IPayInData[] | undefined
  payInSlider: IPayInSlider | undefined
  setPayInData: (value: IPayInData[] | undefined) => void
  setPayInSlider: (value: IPayInSlider | undefined) => void
}

const usePayInStore = create<PayInStore>(
  (set: SetState<PayInStore>, get: GetState<PayInStore>) => ({
    payInData: undefined,
    payInSlider: undefined,
    setPayInData: (value) => set({ payInData: value }),
    setPayInSlider: (value) => set({ payInSlider: value }),
  }),
)

export default usePayInStore
