import React, { FC } from "react";
import styles from "./Search.module.scss";
import Lupa from "assets/Lupa";

interface SearchProps {
  searchTerm: string;
  setSearchTerm: (e: any) => void;
}

const Search: FC<SearchProps> = ({ searchTerm, setSearchTerm }) => {
  return (
    <div className={styles.searchBar}>
      <Lupa className={styles.searchIcon} />
      <input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={styles.searchInput}
      />
    </div>
  );
};

export default Search;
