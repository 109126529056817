import React, { useEffect } from "react";
import styles from "./PayInDetails.module.scss";
import { Menu } from "components/Menu/Menu";
import Header from "assets/Header";
import prev from "assets/prev.svg";
import next from "assets/next.svg";
import { useLocation, useNavigate } from "react-router-dom";
import usePayInStore from "store/payInStore";

const PayInDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentItemIndex, setCurrentItemIndex] = React.useState(0);
  const [touchStartX, setTouchStartX] = React.useState(null);
  const [touchEndX, setTouchEndX] = React.useState(null);
  const { payInSlider } = usePayInStore();

  useEffect(() => {
    const { method, name } = location.state || {};
    if (method && name) {
      if (payInSlider) {
        const index = payInSlider.data.findIndex(
          (item) =>
            item.method.toLowerCase() === method.toLowerCase() &&
            item.name.toLowerCase() === name.toLowerCase()
        );
        if (index !== -1) {
          setCurrentItemIndex(index);
        }
      }
    }
  }, [location.state, navigate]);

  const prevItem = () => {
    if (payInSlider) {
      setCurrentItemIndex((prevIndex) =>
        prevIndex === 0 ? payInSlider.data.length - 1 : prevIndex - 1
      );
    }
  };

  const nextItem = () => {
    if (payInSlider) {
      setCurrentItemIndex((prevIndex) =>
        prevIndex === payInSlider.data.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  if (!payInSlider) {
    return <></>;
  }

  const currentItem = payInSlider.data[currentItemIndex];
  const progressPercentage = ((currentItemIndex + 1) / payInSlider.total) * 100;

  const handleTouchStart = (e: any) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: any) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX !== null && touchEndX !== null) {
      if (touchStartX - touchEndX > 50) {
        nextItem();
      } else if (touchEndX - touchStartX > 50) {
        prevItem();
      }
    }
    setTouchStartX(null);
    setTouchEndX(null);
  };

  return (
    <div
      className={styles.wrapper}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className={styles.navigation}>
        <div className={styles.steps}>
          {currentItemIndex + 1} / {payInSlider.total}
        </div>
        <div className={styles.progressBar}>
          <div
            className={styles.progressBarInner}
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
        <div className={styles.buttons}>
          <img src={prev} alt="prev" onClick={prevItem} />
          <img src={next} alt="next" onClick={nextItem} />
        </div>
      </div>

      <div className={styles.details}>
        <div className={styles.header}>
          <Header />
          <div className={styles.content}>
            <div
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: currentItem.name }}
            />

            <div className={styles.desc}>
              <p dangerouslySetInnerHTML={{ __html: currentItem.code }} />
              <span
                dangerouslySetInnerHTML={{ __html: currentItem.currency }}
              />
            </div>
          </div>
          <img
            src={`/flags/${currentItem.logo.toUpperCase()}.svg`}
            alt={currentItem.code}
            className={styles.flag}
            width={44}
            height={44}
          />
        </div>

        <div className={styles.body}>
          <div className={styles.info}>
            <div className={styles.line}>
              <p>Метод</p>
              <span dangerouslySetInnerHTML={{ __html: currentItem.method }} />
            </div>

            <div className={styles.line}>
              <p>Карты</p>
              <span dangerouslySetInnerHTML={{ __html: currentItem.cards }} />
            </div>

            <div className={styles.line}>
              <p>Тип трафика и клиентов</p>
              <span dangerouslySetInnerHTML={{ __html: currentItem.type }} />
            </div>
          </div>

          <div className={styles.comissions}>
            <h3>Комиссии</h3>

            {currentItem.commissions.in && (
              <div className={styles.line}>
                <p>Прием</p>
                <span
                  style={{ flexDirection: "column" }}
                  dangerouslySetInnerHTML={{
                    __html: currentItem.commissions.in,
                  }}
                />
              </div>
            )}

            {currentItem.commissions.out && (
              <div className={styles.line}>
                <p>Выплаты</p>
                <span
                  style={{ flexDirection: "column" }}
                  dangerouslySetInnerHTML={{
                    __html: currentItem.commissions.out,
                  }}
                />
              </div>
            )}

            {currentItem.commissions.convert && (
              <div className={styles.line}>
                <p>Конвертация в USDT</p>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: currentItem.commissions.convert + " ",
                    }}
                  />
                  <span
                    style={{ color: "#7A7A7B" }}
                    dangerouslySetInnerHTML={{
                      __html: currentItem.commissions.dopInfo,
                    }}
                  />
                </span>
              </div>
            )}
          </div>

          {currentItem.limits && (
            <div className={styles.limits}>
              <h3>Лимиты</h3>

              {currentItem.limits.in && (
                <div className={styles.line}>
                  <p>Прием</p>
                  <span
                    style={{ flexDirection: "column" }}
                    dangerouslySetInnerHTML={{ __html: currentItem.limits.in }}
                  />
                </div>
              )}

              {currentItem.limits.out && (
                <div className={styles.line}>
                  <p>Выплаты</p>
                  <span
                    style={{ flexDirection: "column" }}
                    dangerouslySetInnerHTML={{ __html: currentItem.limits.out }}
                  />
                </div>
              )}

              {currentItem.limits.maxCard && (
                <div className={styles.line}>
                  <p>Max 1 card month</p>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: currentItem.limits.maxCard,
                    }}
                  />
                </div>
              )}
            </div>
          )}

          {currentItem.settlement && (
            <div className={styles.settlement}>
              <h3>Settlement</h3>

              {currentItem.settlement.period && (
                <div className={styles.line}>
                  <p>Период</p>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: currentItem.settlement.period,
                    }}
                  />
                </div>
              )}
            </div>
          )}

          {currentItem.fin ? (
            <div className={styles.fin}>
              <h3>Фин. условия</h3>

              {currentItem.fin.rolling && (
                <div className={styles.line}>
                  <p>Rolling Reserve</p>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: currentItem.fin.rolling,
                    }}
                  />
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div>
        <Menu />
      </div>
    </div>
  );
};

export default PayInDetails;
