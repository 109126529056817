import React from "react";

const Logo = () => {
  return (
    <svg
      width="152"
      height="28"
      viewBox="0 0 152 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.22664 23.0198C4.11498 22.9884 3.9897 23.0089 3.87259 23.0906L3.23256 23.5359C3.11544 23.6176 2.99014 23.638 2.87848 23.6067L6.67785 24.6934C6.78815 24.7247 6.9148 24.7043 7.03191 24.6226L7.67196 24.1773C7.78907 24.0956 7.91571 24.0751 8.02602 24.1065L4.22664 23.0198Z"
        fill="black"
        stroke="black"
        strokeWidth="0.136178"
        stroke-miterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M14.1754 3.39602L13.9071 2.64976C13.854 2.49997 13.7505 2.40328 13.6293 2.36787L9.82996 1.28117C9.95115 1.31522 10.0547 1.41327 10.1078 1.56306L10.376 2.30932C10.4291 2.45775 10.5313 2.55444 10.6525 2.58984L14.4518 3.67655C14.3306 3.6425 14.2285 3.54581 14.1754 3.39602Z"
        fill="#E0DFE3"
        stroke="black"
        strokeWidth="0.136178"
        stroke-miterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M7.04179 25.5261C6.93012 25.4948 6.80484 25.5152 6.68773 25.5969L6.04768 26.0422C5.92921 26.1239 5.80393 26.1457 5.69226 26.113L9.49163 27.1997C9.6033 27.231 9.72994 27.2106 9.84705 27.1289L10.4871 26.6836C10.6042 26.6019 10.7309 26.5815 10.8412 26.6128L7.04179 25.5261Z"
        fill="black"
        stroke="black"
        strokeWidth="0.136178"
        stroke-miterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M17.8816 3.05129L17.6133 2.30503C17.5602 2.1566 17.4567 2.05991 17.3368 2.02451L13.5375 0.937805C13.6587 0.97185 13.7608 1.0699 13.8139 1.21833L14.0822 1.96459C14.1353 2.11302 14.2374 2.20971 14.3586 2.24511L18.158 3.33181C18.0368 3.29777 17.9347 3.20108 17.8816 3.05129Z"
        fill="#E0DFE3"
        stroke="black"
        strokeWidth="0.136178"
        stroke-miterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M10.7458 25.1831C10.6341 25.1518 10.5075 25.1722 10.3903 25.2539L8.21832 26.7628C8.1012 26.8445 7.97456 26.8649 7.86426 26.8336L11.6636 27.9203C11.7753 27.9516 11.9006 27.9312 12.0177 27.8495L14.1897 26.3406C14.3082 26.2589 14.4348 26.2371 14.5451 26.2698L10.7458 25.1831Z"
        fill="black"
        stroke="black"
        strokeWidth="0.136178"
        stroke-miterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M19.7854 3.02713C19.7323 2.87733 19.6288 2.78065 19.5076 2.74524L15.7083 1.65854C15.8295 1.69258 15.9329 1.79063 15.986 1.94043L16.893 4.47198C16.9461 4.62041 17.0496 4.7171 17.1694 4.7525L20.9688 5.8392C20.8476 5.80516 20.7455 5.70711 20.6924 5.55868L19.7854 3.02713Z"
        fill="#E0DFE3"
        stroke="black"
        strokeWidth="0.136178"
        stroke-miterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M18.1654 4.23727C18.4105 4.06704 18.6951 4.16237 18.7973 4.44698L20.9857 10.5518C21.0891 10.8392 20.972 11.2123 20.7269 11.3825L20.0869 11.8278C19.8417 11.9981 19.7246 12.3712 19.8281 12.6558L20.7351 15.1873C20.8386 15.4747 20.7215 15.8478 20.475 16.018L19.8363 16.462C19.5898 16.6336 19.4727 17.0067 19.5762 17.2913L19.8445 18.0375C19.9466 18.3235 19.8295 18.6966 19.5857 18.8655L17.4137 20.3744C17.1672 20.5459 17.0501 20.9191 17.1536 21.2037L17.4219 21.9499C17.524 22.2359 17.4069 22.609 17.1631 22.7779L11.9271 26.4152C11.682 26.5854 11.3974 26.4901 11.2952 26.2055L11.027 25.4592C10.9248 25.1733 10.6402 25.0793 10.3937 25.2509L8.22169 26.7597C7.97657 26.93 7.69195 26.8346 7.58982 26.55L7.32154 25.8038C7.2194 25.5178 6.93481 25.4238 6.68969 25.5941L6.04964 26.0394C5.80316 26.2109 5.51854 26.1156 5.41641 25.8283L4.50948 23.2967C4.40734 23.0108 4.12273 22.9168 3.87761 23.087L3.23756 23.5323C2.99244 23.7026 2.70783 23.6072 2.60569 23.3213L0.41732 17.2164C0.315186 16.9304 0.432303 16.5573 0.676061 16.3884L1.31609 15.9431C1.56121 15.7729 1.67833 15.3998 1.57483 15.1152L0.667883 12.5836C0.564388 12.2963 0.681505 11.9232 0.927987 11.7529L1.56802 11.3076C1.81314 11.1374 1.93025 10.7643 1.82676 10.4797L1.55849 9.73341C1.45636 9.44744 1.57348 9.07431 1.81723 8.90545L3.98927 7.3966C4.23575 7.22501 4.35287 6.85188 4.24937 6.56727L3.9811 5.82102C3.87761 5.53368 3.99471 5.16055 4.23983 4.99033L9.47588 1.35302C9.721 1.18279 10.0056 1.27812 10.1078 1.56409L10.376 2.31035C10.4782 2.59632 10.7628 2.69028 11.0092 2.5187L13.1813 1.00985C13.4264 0.839626 13.711 0.93495 13.8131 1.21956L14.0814 1.96582C14.1835 2.25179 14.4682 2.34575 14.7146 2.17417L15.3533 1.73023C15.5998 1.55865 15.8844 1.65397 15.9865 1.94131L16.8935 4.47285C16.9956 4.75883 17.2802 4.85279 17.5254 4.68257L18.1654 4.23727Z"
        fill="#E0DFE3"
        stroke="black"
        strokeWidth="0.136178"
        stroke-miterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M17.1572 22.782L11.9211 26.4193C11.804 26.501 11.6774 26.5214 11.5671 26.4901L15.3664 27.5768C15.4781 27.6081 15.6034 27.5877 15.7205 27.506L20.9565 23.8687L17.1572 22.782Z"
        fill="black"
        stroke="black"
        strokeWidth="0.136178"
        stroke-miterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M17.1486 21.2065L17.4168 21.9528C17.519 22.2387 17.4018 22.6119 17.1581 22.7807L20.9575 23.8674C21.2026 23.6972 21.3197 23.3241 21.2162 23.0395L20.9479 22.2932L17.1486 21.2065Z"
        fill="#E0DFE3"
        stroke="black"
        strokeWidth="0.136178"
        stroke-miterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M20.7277 11.3811L20.0876 11.8265C19.8425 11.9967 19.7254 12.3698 19.8289 12.6544L23.6283 13.7411C23.5261 13.4551 23.6432 13.082 23.887 12.9132L24.5271 12.4678L20.7277 11.3811Z"
        fill="black"
        stroke="black"
        strokeWidth="0.136178"
        stroke-miterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M22.5975 5.53367C22.5444 5.38524 22.4409 5.28855 22.321 5.25314L18.5217 4.16644C18.6429 4.20049 18.745 4.29854 18.7981 4.44697L20.9865 10.5518C21.09 10.8392 20.9729 11.2123 20.7278 11.3825L24.5271 12.4692C24.7723 12.299 24.8893 11.9259 24.7859 11.6385L22.5975 5.53367Z"
        fill="#E0DFE3"
        stroke="black"
        strokeWidth="0.136178"
        stroke-miterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M19.5827 18.8685L17.4106 20.3774C17.1641 20.549 17.0484 20.9221 17.1505 21.2067L20.9499 22.2934C20.8478 22.0074 20.9649 21.6343 21.21 21.4641L23.382 19.9552L19.5827 18.8685Z"
        fill="black"
        stroke="black"
        strokeWidth="0.136178"
        stroke-miterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M20.4728 16.018L19.8328 16.462C19.5863 16.6336 19.4692 17.0067 19.5727 17.2913L23.372 18.378C23.2699 18.092 23.387 17.7189 23.6321 17.5487L24.2708 17.1047L20.4714 16.018H20.4728Z"
        fill="black"
        stroke="black"
        strokeWidth="0.136178"
        stroke-miterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M19.5736 17.2927L19.8419 18.0389C19.944 18.3249 19.8269 18.6981 19.5831 18.8669L23.3825 19.9536C23.6276 19.7834 23.7447 19.4103 23.6412 19.1256L23.373 18.3794L19.5736 17.2927Z"
        fill="#E0DFE3"
        stroke="black"
        strokeWidth="0.136178"
        stroke-miterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M19.8271 12.6562L20.7341 15.1878C20.8376 15.4751 20.7205 15.8482 20.474 16.0184L24.2734 17.1051C24.5198 16.9336 24.6369 16.5604 24.5335 16.2745L23.6265 13.7429L19.8271 12.6562Z"
        fill="#E0DFE3"
        stroke="black"
        strokeWidth="0.136178"
        stroke-miterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M13.3178 13.183L13.9578 14.9682L12.4245 16.0332L10.8925 17.0967L9.3591 18.1616L8.2724 18.916C8.02728 19.0863 7.91016 19.4594 8.01366 19.744L8.28193 20.4903C8.38406 20.7762 8.66867 20.8716 8.91379 20.7013L10.0005 19.9469C12.1693 18.4407 12.5214 18.1956 14.5979 16.7535L15.6846 15.9991C15.9297 15.8289 16.0454 15.4544 15.9433 15.1684L15.4898 13.902L15.0364 12.6355C14.9342 12.3495 14.6496 12.2556 14.4045 12.4258L13.3178 13.1802V13.183Z"
        fill="black"
      />
      <path
        d="M8.0799 14.5895L8.53336 15.8559C8.63686 16.1433 8.92011 16.2372 9.16659 16.067L10.2533 15.3126L11.7853 14.249L13.3187 13.1841L12.8652 11.9177C12.7617 11.6303 12.4784 11.5364 12.232 11.7066L11.1453 12.461L9.61326 13.5246L8.0799 14.5895Z"
        fill="black"
      />
      <path
        d="M6.80081 11.0165L5.71411 11.7709C5.46899 11.9412 5.35188 12.3143 5.45537 12.6016L5.90884 13.8681L6.36232 15.1345C6.46445 15.4205 6.74907 15.5145 6.99419 15.3443L8.08089 14.5898L7.44084 12.8045L8.97421 11.7396L10.5062 10.6761L12.0396 9.61117L13.1263 8.85674C13.3714 8.68652 13.4885 8.31339 13.385 8.02605L13.1181 7.28116C13.0146 6.99383 12.7314 6.89986 12.4862 7.07009L11.3995 7.82451C9.30833 9.27684 9.4715 9.16175 6.80081 11.0165Z"
        fill="black"
      />
      <path
        d="M42.2864 14.6536C41.633 14.3509 40.7672 14.0858 39.6868 13.8606C38.946 13.6869 38.3728 13.525 37.9623 13.3725C37.5518 13.22 37.2711 13.0511 37.1201 12.8587C36.9668 12.6687 36.8913 12.4434 36.8913 12.183V12.1173C36.8913 11.8827 36.9597 11.6832 37.1013 11.519C37.2404 11.3547 37.474 11.2257 37.7995 11.1365C38.1251 11.045 38.5733 11.0005 39.1371 11.0005C40.0076 11.0005 40.8215 11.099 41.5787 11.2937C42.336 11.4885 43.0272 11.7348 43.6358 12.0211L43.3268 9.36511C42.8125 9.09764 42.1708 8.86536 41.4018 8.67062C40.6304 8.47588 39.7457 8.37733 38.7432 8.37733C37.0541 8.37733 35.7778 8.68235 34.9168 9.29238C34.0557 9.90241 33.624 10.8104 33.624 12.0141V12.1314C33.624 12.8165 33.7585 13.403 34.0298 13.8911C34.2987 14.3814 34.7469 14.792 35.3674 15.1252C35.9901 15.4584 36.837 15.7517 37.9081 16.0027C38.6818 16.1763 39.2834 16.3382 39.7104 16.4907C40.1374 16.6432 40.4346 16.8098 40.6044 16.9905C40.7743 17.1711 40.8592 17.4058 40.8592 17.692V17.7694C40.8592 18.1941 40.6729 18.5132 40.2978 18.7244C39.9227 18.9355 39.3188 19.0435 38.4837 19.0435C37.5259 19.0435 36.6483 18.9308 35.851 18.7056C35.0536 18.4804 34.3247 18.2176 33.6641 17.9126L33.9637 20.7961C34.5205 21.0378 35.1975 21.2583 35.9949 21.4531C36.7922 21.6478 37.7028 21.7464 38.7314 21.7464C40.5502 21.7464 41.909 21.4226 42.8054 20.7727C43.7019 20.1228 44.1501 19.1397 44.1501 17.8234V17.6686C44.1501 16.906 44.0015 16.289 43.7066 15.8174C43.4117 15.3458 42.9352 14.9586 42.2841 14.6536H42.2864Z"
        fill="#1C1B1A"
      />
      <path
        d="M49.3516 18.3486C49.0331 18.0248 48.8751 17.5157 48.8751 16.8212V12.0583H53.119L53.4068 9.35304H48.7925L48.9152 5.32449L45.5842 5.66236L45.596 9.35304H43.7087L44.0296 12.0559H45.5582V17.3139C45.5582 18.2759 45.7021 19.0854 45.9899 19.7423C46.2777 20.4016 46.7448 20.892 47.3936 21.2158C48.0423 21.5396 48.8444 21.6944 49.9343 21.7038C51.2671 21.7155 52.3806 21.6475 53.1638 21.4058L53.4893 18.5199C53.1237 18.6841 52.0692 18.832 50.9675 18.8343C50.1654 18.8343 49.6724 18.6724 49.3539 18.3463L49.3516 18.3486Z"
        fill="#1C1B1A"
      />
      <path
        d="M62.0492 8.40546C61.2046 8.40546 60.5111 8.53685 59.9661 8.80198C59.4212 9.06711 58.9966 9.42608 58.6922 9.88126C58.3879 10.3364 58.1662 10.8409 58.027 11.3946H57.7604L57.8147 8.18725V4.26195H54.4837V21.4343H57.8925L57.725 18.7314H57.9869C58.1261 19.3367 58.3596 19.867 58.6852 20.3151C59.0107 20.7656 59.4424 21.1128 59.9779 21.3616C60.5134 21.6079 61.1645 21.7323 61.9312 21.7323C63.4976 21.7323 64.6771 21.2372 65.4698 20.2447C66.2624 19.2523 66.6587 17.8046 66.6587 15.8994V14.1327C66.6587 12.244 66.2671 10.8174 65.4839 9.85311C64.7007 8.88879 63.5542 8.40546 62.0492 8.40546ZM63.29 16.0308C63.29 17.0092 63.073 17.7436 62.6366 18.2316C62.2001 18.722 61.5608 18.966 60.7163 18.966C60.1666 18.966 59.6854 18.8628 59.2655 18.654C58.8479 18.4451 58.52 18.1354 58.2865 17.7178C58.0506 17.3025 57.9208 16.791 57.8949 16.1857V13.0276C58.0105 12.6851 58.178 12.3777 58.4044 12.1055C58.6663 11.7935 58.9918 11.5448 59.3834 11.3594C59.775 11.1741 60.2185 11.0802 60.7163 11.0802C61.5608 11.0802 62.2001 11.3219 62.6366 11.8075C63.0706 12.2932 63.29 13.0112 63.29 13.9638V16.0285V16.0308Z"
        fill="#1C1B1A"
      />
      <path
        d="M70.7708 4.26195H67.4139V21.4343H70.7708V4.26195Z"
        fill="#1C1B1A"
      />
      <path
        d="M75.8882 18.2903C75.5745 17.9525 75.1003 17.7835 74.4657 17.7835C73.8311 17.7835 73.3475 17.9525 73.0432 18.2903C72.7389 18.6282 72.5856 19.0998 72.5856 19.7051V19.8342C72.5856 20.4325 72.7366 20.8994 73.0432 21.2372C73.3475 21.5751 73.8217 21.744 74.4657 21.744C75.1098 21.744 75.5768 21.5751 75.8882 21.2372C76.1996 20.8994 76.3577 20.4325 76.3577 19.8342V19.7051C76.3577 19.0998 76.202 18.6258 75.8882 18.2903Z"
        fill="#1C1B1A"
      />
      <path
        d="M146.003 21.7332C143.838 21.7332 142.227 21.2349 141.17 20.2382C140.113 19.2415 139.585 17.7981 139.585 15.9078V14.2582C139.585 12.3851 140.079 10.946 141.067 9.94068C142.055 8.93542 143.49 8.43279 145.372 8.43279C146.643 8.43279 147.704 8.65618 148.555 9.10296C149.406 9.54975 150.041 10.1856 150.462 11.0104C150.892 11.8266 151.107 12.8061 151.107 13.9489V14.3999C151.107 14.7093 151.09 15.0272 151.055 15.3537C151.029 15.6716 150.991 15.9723 150.939 16.2558H147.949C147.975 15.7833 147.988 15.3365 147.988 14.9155C147.996 14.4859 148.001 14.0992 148.001 13.7555C148.001 13.1627 147.906 12.6601 147.717 12.2476C147.528 11.8266 147.24 11.5087 146.854 11.2939C146.467 11.0791 145.973 10.9717 145.372 10.9717C144.487 10.9717 143.834 11.2166 143.413 11.7063C142.992 12.1961 142.781 12.892 142.781 13.7942V14.967L142.794 15.3408V16.3331C142.794 16.7284 142.854 17.0935 142.974 17.4286C143.103 17.7637 143.314 18.0559 143.606 18.305C143.898 18.5456 144.285 18.7346 144.766 18.8721C145.256 19.0096 145.866 19.0783 146.596 19.0783C147.386 19.0783 148.138 18.9924 148.851 18.8205C149.573 18.6401 150.256 18.3995 150.901 18.0988L150.617 20.7022C150.041 21.0201 149.363 21.2693 148.581 21.4497C147.807 21.6387 146.948 21.7332 146.003 21.7332ZM141.338 16.2558V14.0649H150.269V16.2558H141.338Z"
        fill="#9A97A9"
      />
      <path
        d="M135.162 21.7461C133.212 21.7461 131.764 21.2306 130.819 20.1996C129.882 19.1685 129.414 17.7208 129.414 15.8563V14.3097C129.414 12.4539 129.887 11.0147 130.832 9.99225C131.777 8.9698 133.22 8.45857 135.162 8.45857C135.669 8.45857 136.142 8.50153 136.58 8.58745C137.027 8.66478 137.431 8.77218 137.791 8.90965C138.161 9.04713 138.483 9.19319 138.758 9.34785L139.029 12.1188C138.608 11.8524 138.135 11.629 137.611 11.4486C137.095 11.2682 136.498 11.1779 135.82 11.1779C134.754 11.1779 133.972 11.4529 133.474 12.0028C132.984 12.5441 132.739 13.3345 132.739 14.3742V15.7403C132.739 16.7714 132.993 17.5661 133.5 18.1246C134.015 18.6831 134.806 18.9623 135.871 18.9623C136.55 18.9623 137.151 18.8764 137.675 18.7046C138.2 18.5241 138.689 18.305 139.145 18.0473L138.874 20.8311C138.453 21.0717 137.925 21.2822 137.289 21.4626C136.653 21.6516 135.944 21.7461 135.162 21.7461Z"
        fill="#9A97A9"
      />
      <path
        d="M124.861 21.4239V13.8329C124.861 13.3002 124.788 12.8405 124.642 12.4539C124.505 12.0672 124.273 11.7665 123.946 11.5517C123.62 11.3369 123.173 11.2295 122.606 11.2295C122.108 11.2295 121.669 11.3197 121.291 11.5001C120.922 11.6806 120.617 11.9255 120.376 12.2348C120.144 12.5355 119.968 12.8792 119.848 13.2658L119.332 11.4615H119.951C120.088 10.903 120.316 10.4004 120.634 9.95359C120.961 9.5068 121.399 9.15453 121.949 8.89677C122.507 8.63041 123.203 8.49724 124.036 8.49724C125.007 8.49724 125.794 8.68197 126.395 9.05142C126.996 9.41229 127.439 9.95359 127.722 10.6753C128.015 11.397 128.161 12.2906 128.161 13.356V21.4239H124.861ZM116.626 21.4239V8.78077H119.925L119.796 11.861L119.925 12.1317V21.4239H116.626Z"
        fill="#9A97A9"
      />
      <path
        d="M112.117 21.4239L112.233 18.3308L112.143 18.0473V14.0906L112.13 13.5236C112.13 12.6987 111.902 12.093 111.447 11.7064C111 11.3197 110.266 11.1264 109.243 11.1264C108.375 11.1264 107.559 11.2424 106.794 11.4744C106.038 11.6978 105.338 11.9598 104.694 12.2605L104.977 9.63139C105.355 9.43377 105.785 9.24904 106.266 9.0772C106.756 8.89677 107.301 8.75071 107.903 8.63901C108.504 8.52731 109.153 8.47147 109.849 8.47147C110.88 8.47147 111.752 8.59605 112.465 8.84522C113.178 9.0858 113.745 9.43377 114.166 9.88915C114.596 10.3445 114.905 10.8901 115.094 11.5259C115.283 12.1531 115.378 12.8491 115.378 13.6138V21.4239H112.117ZM107.98 21.7204C106.726 21.7204 105.768 21.4068 105.106 20.7795C104.453 20.1523 104.127 19.2587 104.127 18.0988V17.738C104.127 16.5093 104.505 15.6028 105.261 15.0186C106.017 14.4257 107.215 14.0176 108.856 13.7942L112.439 13.3045L112.633 15.431L109.333 15.9079C108.612 16.0024 108.096 16.1742 107.787 16.4234C107.486 16.6726 107.336 17.0377 107.336 17.5189V17.6477C107.336 18.1203 107.482 18.4898 107.774 18.7561C108.075 19.0139 108.543 19.1428 109.179 19.1428C109.746 19.1428 110.231 19.0525 110.635 18.8721C111.039 18.6917 111.37 18.4554 111.627 18.1633C111.894 17.8625 112.083 17.5275 112.194 17.158L112.658 18.7948H112.091C111.954 19.3361 111.731 19.8301 111.421 20.2769C111.12 20.7151 110.695 21.0674 110.145 21.3337C109.595 21.5915 108.874 21.7204 107.98 21.7204Z"
        fill="#9A97A9"
      />
      <path
        d="M99.7651 21.4239V13.8329C99.7651 13.3002 99.692 12.8405 99.546 12.4539C99.4085 12.0672 99.1765 11.7665 98.85 11.5517C98.5235 11.3369 98.0767 11.2295 97.5096 11.2295C97.0113 11.2295 96.5731 11.3197 96.1951 11.5001C95.8256 11.6806 95.5206 11.9255 95.28 12.2348C95.048 12.5355 94.8719 12.8792 94.7516 13.2658L94.2361 11.4615H94.8547C94.9922 10.903 95.2199 10.4004 95.5378 9.95359C95.8643 9.5068 96.3025 9.15453 96.8524 8.89677C97.4108 8.63041 98.1068 8.49724 98.9402 8.49724C99.9111 8.49724 100.697 8.68197 101.299 9.05142C101.9 9.41229 102.343 9.95359 102.626 10.6753C102.918 11.397 103.064 12.2906 103.064 13.356V21.4239H99.7651ZM91.5296 21.4239V8.78077H94.8289L94.7001 11.861L94.8289 12.1317V21.4239H91.5296Z"
        fill="#9A97A9"
      />
      <path
        d="M86.3685 21.424V8.78085H89.6678V21.424H86.3685ZM88.0182 7.28584C87.3909 7.28584 86.927 7.13977 86.6262 6.84764C86.3341 6.54692 86.188 6.13451 86.188 5.61039V5.54595C86.188 5.02184 86.3341 4.60942 86.6262 4.3087C86.927 4.00798 87.3909 3.85762 88.0182 3.85762C88.6368 3.85762 89.0964 4.00798 89.3972 4.3087C89.6979 4.60942 89.8483 5.02184 89.8483 5.54595V5.61039C89.8483 6.1431 89.6979 6.55552 89.3972 6.84764C89.0964 7.13977 88.6368 7.28584 88.0182 7.28584Z"
        fill="#9A97A9"
      />
      <path
        d="M82.0537 3.98653C82.7239 3.98653 83.3425 4.04237 83.9096 4.15407C84.4766 4.26577 84.9793 4.40324 85.4175 4.56649L85.7139 7.04099C85.3444 6.92929 84.9578 6.83908 84.554 6.77034C84.1587 6.69301 83.7205 6.65435 83.2394 6.65435C82.6637 6.65435 82.2083 6.71879 81.8733 6.84767C81.5468 6.97655 81.3148 7.16128 81.1773 7.40186C81.0398 7.64243 80.9711 7.92597 80.9711 8.25247V8.29113C80.9711 8.52311 81.0055 8.74221 81.0742 8.94842C81.1429 9.15463 81.2246 9.33936 81.3191 9.5026L79.1668 9.57993V9.23196C78.7715 9.04293 78.4364 8.76369 78.1615 8.39423C77.8866 8.02478 77.7491 7.5651 77.7491 7.01521V6.95078C77.7491 6.04002 78.0971 5.31829 78.793 4.78559C79.4976 4.25288 80.5845 3.98653 82.0537 3.98653ZM78.1873 21.424V10.263H81.4737V21.424H78.1873ZM76.3572 11.9384V9.32217L79.5534 9.34795L80.8422 9.32217H85.6881L85.4046 11.9384H76.3572Z"
        fill="#9A97A9"
      />
    </svg>
  );
};

export default Logo;
