import { Menu } from "components/Menu/Menu";
import React, { useEffect, useState } from "react";
import styles from "./PayOut.module.scss";
import cn from "classnames";
import Accordion from "components/Accordion/Accordion";
import Search from "components/Search/Search";
import { useNavigate } from "react-router-dom";
import usePayOutStore from "store/payOutStore";
import { IPayOutData } from "types/IPayOutData";

const PayOut = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [filteredData, setFilteredData] = useState<IPayOutData[] | null>(null);
  const navigate = useNavigate();
  const { payOutData } = usePayOutStore();

  const index = 1;

  const handleAccordionClick = () => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleItemClick = (method: string) => {
    navigate("/detailsout", { state: { method } });
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (payOutData) {
      const localData = payOutData.filter((obj) =>
        obj.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(localData);
    }
  }, [payOutData]);

  return (
    <div className={styles.wrapper}>
      <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      {filteredData && filteredData.length === 0 ? (
        <div className={styles.empty}>Ничего не найдено</div>
      ) : (
        ""
      )}
      <div className={styles.content}>
        {filteredData &&
          filteredData.map((obj, count) => (
            <div key={count} className={styles.item}>
              <div className={styles.line}>
                <div className={styles.info}>
                  <img
                    src={`/flags/${obj.icon.toUpperCase()}.svg`}
                    alt={obj.icon}
                    width={26}
                    height={26}
                  />
                  <p
                    className={styles.name}
                    dangerouslySetInnerHTML={{ __html: obj.name }}
                  />
                </div>

                <div
                  className={styles.code}
                  dangerouslySetInnerHTML={{ __html: obj.code }}
                />
              </div>

              <div className={styles.variants}>
                {obj.method.map((method, index) => (
                  <div
                    key={index}
                    className={cn(
                      styles.variant,
                      obj.method.length > 1 ? styles.border : null
                    )}
                    onClick={() => handleItemClick(method)}
                    dangerouslySetInnerHTML={{ __html: method }}
                  />
                ))}
              </div>
            </div>
          ))}
      </div>

      {/* <div className={styles.accordion}>
        <Accordion
          active={index === activeIndex}
          title={"Европа + Мир"}
          content={
            "Austria, Australia, Belgium, Bulgaria, Bahrain, Canada, Switzerland, Chile, Cyprus, Czech Republic, Germany, Denmark, Dominican Republic, Estonia, Spain, Finland, France, United Kingdom, Greece, Hong Kong, Croatia, Hungary, Ireland, India, Iceland, Italy, Japan, Lithuania, Luxembourg, North Macedonia, Malta, Netherlands, Norway, New Zealand, Poland, Portugal, Romania, Saudi Arabia, Sweden, Slovenia, Slovakia, Turkey."
          }
          onClick={() => handleAccordionClick()}
        />
      </div> */}

      <div className={styles.menu}>
        <Menu />
      </div>
    </div>
  );
};

export default PayOut;
