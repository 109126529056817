import React, { useEffect, useState } from "react";
import styles from "./LoadingPage.module.scss";
import Logo from "assets/Logo";
import hand from "assets/Hand.svg";
import card from "assets/Card.svg";
import tCoin from "assets/TCoin.svg";
import EndGray from "assets/EndGray";
import PercCoin from "assets/PercCoin.svg"
import { useNavigate } from "react-router-dom";
import ProgressLoading from "components/ProgressLoading/ProgressLoading";
import usePayInStore from "store/payInStore";
import usePayInOutStore from "store/payInOutStore";
import usePayOutStore from "store/payOutStore";


function LoadingPage() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { payInData } = usePayInStore()
    const { payInOutData } = usePayInOutStore()
    const { payOutData } = usePayOutStore()

    useEffect(() => {
        setLoading(true);
    
        const secondTimerId = setTimeout(() => {
          if (payInOutData && payInOutData.length > 0) navigate("/payinout");
          else if (payInData && payInData.length > 0) navigate("/payin");
          else if (payOutData && payOutData.length > 0) navigate("/payin");
        }, 3000);
    
        return () => {
          setLoading(false);
          clearTimeout(secondTimerId);
        };
      }, [navigate]);

  return (
    <div className={styles.wrapper}>
        <div className={styles.progressBar}>
          {loading 
          && <ProgressLoading />
          }
        </div>
      <div className={styles.content}>
        <div className={styles.logo}>
          <Logo />
        </div>

        <div className={styles.title}>Коммерческое предложение</div>
        <div className={styles.desc}>
          Надежные решения для <span> High Risk </span> бизнеса
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.image}>
          <img src={hand} alt="hand" className={styles.hand} />
          <img src={card} alt="card" className={styles.card} />
          <img src={tCoin} alt="tCoin" className={styles.tCoin} />

          <EndGray className={styles.end} />

          <img src={PercCoin} alt="PercCoin" className={styles.PercCoin} />

          
        </div>
      </div>
    </div>
  );
}

export default LoadingPage;
