import { IPayInOutData } from "types/IPayInOutData"
import { IPayInOutSlider } from "types/IPayInOutSlider"
import create, { GetState, SetState } from "zustand"

interface PayInOutStore {
  payInOutData: IPayInOutData[] | undefined
  payInOutSlider: IPayInOutSlider | undefined
  setPayInOutData: (value: IPayInOutData[] | undefined) => void
  setPayInOutSlider: (value: IPayInOutSlider | undefined) => void
}

const usePayInOutStore = create<PayInOutStore>(
  (set: SetState<PayInOutStore>, get: GetState<PayInOutStore>) => ({
    payInOutData: undefined,
    payInOutSlider: undefined,
    setPayInOutData: (value) => set({ payInOutData: value }),
    setPayInOutSlider: (value) => set({ payInOutSlider: value }),
  }),
)

export default usePayInOutStore
