import React from "react";

const EndGray = ({ fill, className }: { fill?: string; className: string }) => {
  return (
    <svg
      width="97"
      height="83"
      viewBox="0 0 97 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M41.0059 2.55364C39.428 0.175863 29.6075 3.95497 19.0821 10.9563C8.56645 17.9488 1.32118 25.5359 2.8751 27.9218C2.8751 27.9218 2.8751 27.9218 2.87485 27.9265C20.8944 52.2873 44.1213 70.0782 69.294 78.5603L85.0057 36.1396C68.7812 31.0988 53.4036 19.2313 41.0014 2.5487L41.0059 2.55364Z"
        fill={fill}
        stroke="black"
        strokeWidth="0.469656"
        stroke-miterlimit="10"
      />
      <path
        d="M8.58226 19.0785C6.43242 21.0634 4.77969 22.8956 3.77362 24.4136C21.5603 48.2825 44.0728 66.1535 68.5536 75.7234L68.8827 66.9319C46.1697 57.7322 25.2753 41.1124 8.58226 19.0785Z"
        fill="black"
      />
      <path
        d="M86.6019 60.8707C90.9583 49.163 90.2431 38.0918 85.0043 36.1424C79.7655 34.1931 71.987 42.1037 67.6305 53.8114C63.2741 65.5191 63.9893 76.5903 69.2281 78.5397C74.4669 80.489 82.2454 72.5784 86.6019 60.8707Z"
        fill={fill}
        stroke="black"
        strokeWidth="0.469656"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default EndGray;
