import Root from "Root";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import LocationProvider from "provider/LocationProvider";
import { fetchData } from "api/api";
import { transformData } from "utils/utils";
import usePayInStore from "store/payInStore";
import usePayInOutStore from "store/payInOutStore";
import usePayOutStore from "store/payOutStore";

const App = () => {

  const { setPayInData, setPayInSlider } = usePayInStore()
  const { setPayInOutData, setPayInOutSlider } = usePayInOutStore()
  const { setPayOutData, setPayOutSlider } = usePayOutStore()

  const getData = async () => {
    try {
      const data = await fetchData()
      if (data) {
        const tData = transformData(data)
        setPayInData(tData.PayInData)
        setPayInSlider(tData.PayInSlider)
        setPayInOutData(tData.PayInOutData)
        setPayInOutSlider(tData.PayInOutSlider)
        setPayOutData(tData.PayOutData)
        setPayOutSlider(tData.PayOutSlider)
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <BrowserRouter>
      <LocationProvider>
        <Root />
      </LocationProvider>
    </BrowserRouter>
  );
};

export default App;
