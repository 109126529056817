import { IPayOutData } from "types/IPayOutData"
import { IPayOutSlider } from "types/IPayOutSlider"
import create, { GetState, SetState } from "zustand"

interface PayOutStore {
  payOutData: IPayOutData[] | undefined
  payOutSlider: IPayOutSlider | undefined
  setPayOutData: (value: IPayOutData[] | undefined) => void
  setPayOutSlider: (value: IPayOutSlider | undefined) => void
}

const usePayOutStore = create<PayOutStore>(
  (set: SetState<PayOutStore>, get: GetState<PayOutStore>) => ({
    payOutData: undefined,
    payOutSlider: undefined,
    setPayOutData: (value) => set({ payOutData: value }),
    setPayOutSlider: (value) => set({ payOutSlider: value }),
  }),
)

export default usePayOutStore
