import { OFFER_TOKEN, fetchData } from "api/api";
import Auth from "pages/Auth/Auth";
import { useEffect, useState } from "react";
import { Routes, Route, useParams, useLocation } from "react-router-dom";
import { routes } from "routes";
import usePayInOutStore from "store/payInOutStore";
import usePayInStore from "store/payInStore";
import usePayOutStore from "store/payOutStore";
import { transformData } from "utils/utils";

const Root = () => {
  const location = useLocation();

  const { setPayInData, setPayInSlider } = usePayInStore()
  const { setPayInOutData, setPayInOutSlider } = usePayInOutStore()
  const { setPayOutData, setPayOutSlider } = usePayOutStore()

  const [isAuth, setIsAuth] = useState<boolean>(false)
  const [isCheck, setIsCheck] = useState<boolean>(false)
  const [offerToken, setOfferToken] = useState('')
  const [error, setError] = useState('')

  const getData = async () => {
    try {
      const data = await fetchData()
      if (data) {
        const tData = transformData(data)
        setPayInData(tData.PayInData)
        setPayInSlider(tData.PayInSlider)
        setPayInOutData(tData.PayInOutData)
        setPayInOutSlider(tData.PayInOutSlider)
        setPayOutData(tData.PayOutData)
        setPayOutSlider(tData.PayOutSlider)
        
        setIsAuth(true)
      } else {
        if (offerToken) setError('Неверный токен')
      }
    } catch (e) {
      console.error(e)
      setError('Неизвестная ошибка сервера')
    } finally {
      setIsCheck(true)
    }  
  }

  // const updateOfferToken = () => {
  //   localStorage.setItem(OFFER_TOKEN, offerToken)
  //   if (offerToken) {
  //     getData()
  //   }
  // }

  useEffect(() => {
    const extractToken = (queryString: string) => {
      const match = queryString.match(/token=([^&]+)/);
      return match ? match[1] : null;
    }
    // const checkToken = localStorage.getItem(OFFER_TOKEN)

    if (location.search) {
      const checkToken = extractToken(location.search)
      console.log(checkToken)
      if (checkToken) {
        localStorage.setItem(OFFER_TOKEN, checkToken)
        setOfferToken(checkToken)
        getData()
      }
    } else {
      const checkToken = localStorage.getItem(OFFER_TOKEN)
      if (checkToken) {
        setOfferToken(checkToken)
        getData()
      }
    }
  }, [])

  if (!isCheck && !isAuth) return <></>

  // if (!isAuth && isCheck) return <Auth error={error} updateOfferToken={updateOfferToken} offerToken={offerToken} setOfferToken={setOfferToken} />

  return (
    <div>
      <Routes>
        {isAuth && isCheck && routes.map(({ path, Component }, count) => (
          <Route
            key={count}
            path={path}
            element={Component ? <Component /> : <></>}
          />
        ))}
      </Routes>
    </div>
  );
};

export default Root;
