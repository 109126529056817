import LoadingPage from "pages/LoadingPage/LoadingPage"
import MainPage from "pages/MainPage/MainPage"
import PayIn from "pages/PayIn/PayIn"
import PayInDetails from "pages/PayInDetails/PayInDetails"
import PayInOut from "pages/PayInOut/PayInOut"
import PayInOutDetails from "pages/PayInOutDetails/PayInOutDetails"
import PayOut from "pages/PayOut/PayOut"
import PayOutDetails from "pages/PayOutDetails/PayOutDetails"

export const LOADING_ROUTE = "/"
export const MAIN_ROUTE = "/main"
export const PAYINOUT_ROUTE = "/payinout"
export const PAYIN_ROUTE = "/payin"
export const PAYOUT_ROUTE = "/payout"
export const DETAILSINOUT_ROUTE = '/detailsinout'
export const DETAILSIN_ROUTE = '/detailsin'
export const DETAILSOUT_ROUTE = '/detailsout'




export const routes = [
    {
        path: LOADING_ROUTE, 
        Component: LoadingPage
    },
    // {
    //     path: MAIN_ROUTE, 
    //     Component: MainPage
    // },
    {
        path: PAYINOUT_ROUTE, 
        Component: PayInOut
    },
    {
        path: PAYIN_ROUTE, 
        Component: PayIn
    },
    {
        path: PAYOUT_ROUTE, 
        Component: PayOut
    },
    {
        path: DETAILSINOUT_ROUTE,
        Component: PayInOutDetails
    },
    {
        path: DETAILSOUT_ROUTE,
        Component: PayOutDetails
    },
    {
        path: DETAILSIN_ROUTE,
        Component: PayInDetails
    },
]