import { Menu } from "components/Menu/Menu";
import styles from "./PayInOut.module.scss";
import cn from "classnames";
import Search from "components/Search/Search";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IPayInOutData } from "types/IPayInOutData";
import payInOutStore from "store/payInOutStore";

const PayInOut = () => {
  const { payInOutData } = payInOutStore();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredData, setFilteredData] = useState<IPayInOutData[] | null>(
    null
  );

  const navigate = useNavigate();

  const handleItemClick = (method: string, name: string) => {
    navigate("/detailsinout", { state: { method, name } });
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (payInOutData) {
      const localData = payInOutData.filter((obj) =>
        obj.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(localData);
    }
  }, [payInOutData]);

  return (
    <div className={styles.wrapper}>
      <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      {filteredData && filteredData.length === 0 ? (
        <div className={styles.empty}>Ничего не найдено</div>
      ) : (
        ""
      )}
      <div className={styles.content}>
        {filteredData &&
          filteredData.map((obj, count) => (
            <div key={count} className={styles.item}>
              <div className={styles.line}>
                <div className={styles.info}>
                  <img
                    src={`/flags/${obj.code.toUpperCase()}.svg`}
                    alt={obj.code}
                    width={26}
                    height={26}
                  />
                  <p
                    className={styles.name}
                    dangerouslySetInnerHTML={{ __html: obj.name }}
                  />
                </div>

                <div
                  className={styles.code}
                  dangerouslySetInnerHTML={{ __html: obj.code }}
                />
              </div>

              <div className={styles.variants}>
                {obj.method.map((method, index) => (
                  <div
                    key={index}
                    className={cn(
                      styles.variant,
                      obj.method.length > 1 ? styles.border : null
                    )}
                    onClick={() => handleItemClick(method, obj.name)}
                    dangerouslySetInnerHTML={{ __html: method }}
                  />
                ))}
              </div>
            </div>
          ))}
      </div>

      <div className={styles.menu}>
        <Menu />
      </div>
    </div>
  );
};

export default PayInOut;
